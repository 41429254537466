var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"p-4 py-20 min-h-screen max-w-6xl mx-auto"},[_vm._m(0),_c('div',{staticClass:"space-y-4"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"py-4"},[_c('div',{directives:[{name:"in-viewport",rawName:"v-in-viewport",value:({ margin: '-50% 0px' }),expression:"{ margin: '-50% 0px' }"}],staticClass:"blur-type-1"},[(_vm.APIData.matches)?_c('Card',{staticClass:"mx-auto my-10",attrs:{"mode":"chrono","match":_vm.APIData.matches[0]}}):_vm._e()],1)]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"py-4"},[_c('div',{directives:[{name:"in-viewport",rawName:"v-in-viewport",value:({ margin: '-50% 0px' }),expression:"{ margin: '-50% 0px' }"}],staticClass:"//blur-type-2"},[(_vm.APIData.matches)?_c('Card',{staticClass:"mx-auto my-10",attrs:{"mode":"chrono","match":_vm.APIData.matches[0]}}):_vm._e()],1)]),_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"flex items-center justify-center mt-10"},[_c('svg',{attrs:{"width":"2","height":"61","viewBox":"0 0 2 61","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"1","y1":"0.108398","x2":"1","y2":"60.1167","stroke":"black","stroke-opacity":"0.4","stroke-width":"2","stroke-dasharray":"8 8"}})])]),_c('h2',{staticClass:"font-bold uppercase text-brandGrey text-2xl mb-4 mt-10"},[_vm._v(" WHAT YOU CAN USE NUMBERS GAME FOR ")]),_vm._m(7),_c('div',{staticClass:"flex items-center justify-center mt-20"},[_c('svg',{attrs:{"width":"2","height":"61","viewBox":"0 0 2 61","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"1","y1":"0.108398","x2":"1","y2":"60.1167","stroke":"black","stroke-opacity":"0.4","stroke-width":"2","stroke-dasharray":"8 8"}})])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-8"},[_c('img',{attrs:{"src":require("@/assets/how-to-use.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("1. Check the match cards:")]),_vm._v(" Look at the match cards provided by the website, which will display all of the data for a given match. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("2. See our predictions:")]),_vm._v(" Our algorithm gives you the percentage chance of each team to win, immediately giving you a summary of the underlying data. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("3. Compare the odds:")]),_vm._v(" Observe the best_odds (the best odds currently available on the market) and the fair_odds (the true likelihood of each result calculated using machine learning match predictions). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("4. Look for value:")]),_vm._v(" Pay attention to the best_odds highlighted in green, as these represent cases where the odds on offer are higher than the fair_odds. This is an indication of good value. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("5. Analyse the available data:")]),_vm._v(" Pressing the more info button gives you access to the full data. Take into account various factors like ×G (expected goals) for and against each team, head-to-head performance, and recent form. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("6. Make a decision:")]),_vm._v(" Based on your analysis and the information provided by the Numbers Game, decide whether you want to back an upset, the favourite, or choose a side in an evenly matched game. You can use the odds to sway your decision if you're uncertain. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-4"},[_c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("Informed decision-making:")]),_vm._v(" Numbers Game provides predictions, team, and individual stats for football clubs across the world, enabling you to make better-informed decisions and maximise your value. ")]),_c('p',{staticClass:"py-10"},[_c('img',{staticClass:"w-[200px] mx-auto",attrs:{"src":require("@/assets/computer.png")}})]),_c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("Data-driven predictions:")]),_vm._v(" Use the data provided in the ‘MORE INFO’ section of every card to increase the accuracy of your picks, rather than just going off hunches. ")]),_c('p',[_c('strong',{staticClass:"font-bold"},[_vm._v("An edge on the market:")]),_vm._v(" Often odds are lengthened just to entice punters into making poor decisions - Numbers Game is here to show you when odds are longer than they should be, so you can take advantage. ")])])
}]

export { render, staticRenderFns }